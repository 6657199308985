import React from 'react';
import { truncate } from 'lodash';
import { CaseStudy } from '../../common/fragments/CaseStudy';
import { LocaleLink } from '../common/links/LocaleLink';
import { Div, Img } from '../common/Elements';
import { Lazy } from '../common/Lazy';
import { TextVariantMap } from '../common/Text/Text.interface';
import { useStaticStrings } from '../../common/hooks/useStaticStrings';
import Text from '../common/Text/Text';

interface CaseStudiesCardContentProps {
  learnMoreUrl: string;
  logo: string;
  description: string;
  grouped?: boolean;
  learnMoreText?: boolean;
  logos?: string[];
}

export const CaseStudiesCardContent: React.FC<CaseStudiesCardContentProps> = ({
  learnMoreUrl,
  logo,
  grouped,
  description,
  learnMoreText,
  logos,
}) => {
  const staticStrings = useStaticStrings();
  return (
    <Div
      display="flex"
      justifyContent="between"
      flexDirection="column"
      heightPercentage={100}
      paddingLeft={[0, 0, 0, grouped ? 4 : 0]}
    >
      <LocaleLink
        to={learnMoreUrl}
        display="block"
        flex="auto"
        marginBottom={[10, 10, 10, 0]}
        data-tracking-action="click"
        data-tracking-category="Case Studies Variant - Product Link"
        data-tracking-label={learnMoreUrl}
      >
        {logo && (
          <Div>
            <Lazy>
              <Img src={logo} style={{ maxHeight: '5rem' }} />
            </Lazy>
          </Div>
        )}
      </LocaleLink>
      <Text variant="body2" marginTop={0} marginBottom={4} tag="P">
        {truncate(description, {
          length: 121,
        })}
      </Text>
      {logos?.length && (
        <Div marginBottom={4} flexWrap="wrap" display="flex">
          {logos?.map((logo, index) => {
            const marginBasedOnLastIndex = index === logos.length - 1 ? 0 : 2;
            return (
              <Img
                height={2}
                marginRight={1}
                marginBottom={[
                  marginBasedOnLastIndex,
                  marginBasedOnLastIndex,
                  marginBasedOnLastIndex,
                  0,
                ]}
                src={logo}
              />
            );
          })}
        </Div>
      )}

      <LocaleLink
        to={learnMoreUrl}
        data-tracking-action="click"
        data-tracking-category="Case Studies Variant - Product Link"
        data-tracking-label={learnMoreUrl}
        {...TextVariantMap.learnmore}
        className="learn-more"
      >
        {learnMoreText || staticStrings.json_values.Learn_More}
      </LocaleLink>
    </Div>
  );
};
