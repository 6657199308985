import * as React from 'react';
import { useLocale } from '../../common/hooks/useLocale';
import { caseStudiesBasePath } from './templates/config';
import { Col, Label, Row } from '../common/Elements';
import { pathJoin, resolveUrl } from '../../common/util/path';
import Text from '../common/Text/Text';
import { TextVariantMap } from '../common/Text/Text.interface';
import { StyleSystemProps } from '../common/Elements/Elements';
import { SelectDropdown } from '../common/SelectDropdown';

export interface CaseStudyFilter {
  industry: string | null;
  region: string | null;
  usecase: string | null;
  product: string | null;
  showing: 'all' | null;
}

const filterKeys: ReadonlyArray<keyof CaseStudyFilter> = [
  'industry',
  'region',
  'usecase',
  'product',
  'showing',
] as const;

interface CaseStudiesFiltersProps {
  content: any;
  englishContent: any;
  filters: CaseStudyFilter;
  setFilters: (filters: CaseStudyFilter) => void;
}

export function useCaseStudiesFilters() {
  const locale = useLocale();
  const initialFilters: CaseStudyFilter = {
    industry: null,
    region: null,
    usecase: null,
    product: null,
    showing: null,
  };

  const [filters, setFilters] = React.useState(initialFilters);

  React.useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const newFilters = { ...initialFilters };

    filterKeys.forEach(key => {
      if (key === 'showing') {
        const value: unknown = urlParams.get(key);

        if (isValueOkayForShowingFilter(value)) {
          newFilters[key] = value;
        }
      } else {
        newFilters[key] = urlParams.get(key);
      }
    });

    setFilters(newFilters);
  }, []);

  React.useEffect(() => {
    const params = [
      ['industry', filters && filters.industry],
      ['region', filters && filters.region],
      ['usecase', filters && filters.usecase],
      ['product', filters && filters.product],
    ].filter(([_, value]) => !!value);

    const search = new URLSearchParams(params as any);

    window.history.replaceState(
      {},
      document.head.title,
      resolveUrl(
        locale,
        pathJoin('/', caseStudiesBasePath, '/') +
          `${params.length > 0 ? '?' : ''}${search.toString()}`
      )
    );
  }, [filters.industry, filters.region, filters.usecase, filters.product]);

  return [filters, setFilters] as const;
}

export function isFilterKey(key: string): key is Exclude<keyof CaseStudyFilter, 'showing'> {
  return filterKeys.includes(key as any);
}

export function isValueOkayForShowingFilter(value: any): value is CaseStudyFilter['showing'] {
  return value === 'all' || value === null;
}

export const CaseStudiesFilters: React.FC<CaseStudiesFiltersProps> = props => {
  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const key = e.target.name;

    if (isFilterKey(key)) {
      props.setFilters({
        ...props.filters,
        [key]: e.target.value === '' ? null : e.target.value,
      });
    }
  };
  const focused: Pick<
    StyleSystemProps,
    'borderColor' | 'outlineColor' | 'outlineStyle' | 'outlineWidth' | 'outlineInnerOffset'
  > = {
    outlineWidth: 2,
    outlineStyle: 'solid',
    outlineColor: 'blue2',
    outlineInnerOffset: 2,
    borderColor: 'transparent',
  };
  return (
    <>
      <Row>
        <Col lg={12}>
          <Text variant="body2-bold" marginBottom={1} tag="P">
            {props.content.filterBy}:
          </Text>
        </Col>
      </Row>
      <Row marginBottom={5}>
        <Col lg={3} marginBottom={[2, 2, 2, 0]}>
          <Label htmlFor="industry">
            <Text marginBottom={1} display="none" variant="body2-bold">
              {props.filters.industry}
            </Text>
            <SelectDropdown
              id="industry"
              value={props.filters.industry || undefined}
              styleprops={{
                width: '100%',
                paddingVertical: 1,
                ...TextVariantMap.body2,
                focused,
              }}
              title="industry"
              name="industry"
              data-key="industry"
              onChange={onChange as any}
            >
              {(props.englishContent.industryFilter as string[]).map((industry, i) => (
                <option key={`industry-${industry}`} value={i === 0 ? '' : industry}>
                  {props.content.industryFilter[i]}
                </option>
              ))}
            </SelectDropdown>
          </Label>
        </Col>
        <Col lg={3} marginBottom={[2, 2, 2, 0]}>
          <Label htmlFor="region">
            <Text marginBottom={1} display="none" variant="body2-bold">
              {props.filters.region}
            </Text>
            <SelectDropdown
              id="region"
              value={props.filters.region || undefined}
              styleprops={{
                width: '100%',
                paddingVertical: 1,
                ...TextVariantMap.body2,
                focused,
              }}
              title="region"
              name="region"
              data-key="region"
              onChange={onChange as any}
            >
              {(props.englishContent.regionFilter as string[]).map((region, i) => (
                <option key={`region-${region}`} value={i === 0 ? '' : region}>
                  {props.content.regionFilter[i]}
                </option>
              ))}
            </SelectDropdown>
          </Label>
        </Col>
        <Col lg={3} marginBottom={[2, 2, 2, 0]}>
          <Label htmlFor="usecase">
            <Text marginBottom={1} display="none" variant="body2-bold">
              {props.filters.usecase}
            </Text>
            <SelectDropdown
              id="usecase"
              value={props.filters.usecase || undefined}
              styleprops={{
                width: '100%',
                paddingVertical: 1,
                ...TextVariantMap.body2,
                focused,
              }}
              title="use case"
              name="usecase"
              data-key="usecase"
              onChange={onChange as any}
            >
              {(props.englishContent.usecaseFilter as string[]).map((usecase, i) => (
                <option key={`usecase-${usecase}`} value={i === 0 ? '' : usecase}>
                  {props.content.usecaseFilter[i]}
                </option>
              ))}
            </SelectDropdown>
          </Label>
        </Col>
        <Col lg={3}>
          <Label htmlFor="product">
            <Text marginBottom={1} display="none" variant="body2-bold">
              {props.filters.product}
            </Text>
            <SelectDropdown
              id="usecase"
              value={props.filters.product || undefined}
              styleprops={{
                width: '100%',
                paddingVertical: 1,
                ...TextVariantMap.body2,
                focused,
              }}
              title="product"
              name="product"
              data-key="product"
              onChange={onChange as any}
            >
              {(props.englishContent.productFilter as string[]).map((product, i) => (
                <option key={`product-${product}`} value={i === 0 ? '' : product}>
                  {props.content.productFilter[i]}
                </option>
              ))}
            </SelectDropdown>
          </Label>
        </Col>
      </Row>
    </>
  );
};
