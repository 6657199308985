type MRKCommonContent = {
  meta_templateVariableName: string;
  mkd_content: string;
};

/**
 * Given some MRK Common Content array, reduce it down to a single object
 * whose keys are derived from teh name and the values are the content
 *
 * Copied from marketing site: function toTemplateData
 * https://bitbucket.cfdata.org/projects/MAR/repos/marketingsite/browse/src/lib/kvstore.js#280
 *
 * @param contents The MRK common content array from the template
 */
export function getObjectVersionOfMRKTemplate(contents: MRKCommonContent[]) {
  return contents.reduce((a, b) => {
    // support arrays by looking at the variable name to see if it ends with '[]'.
    // example: keyFeatures[]
    if (!b.meta_templateVariableName) {
      throw new Error(`no meta_templateVariableName found for ${b}`);
    }

    const isArray = b.meta_templateVariableName.endsWith('[]');
    const varName = isArray
      ? b.meta_templateVariableName.substr(0, b.meta_templateVariableName.length - 2)
      : b.meta_templateVariableName;

    if (isArray) {
      a[varName] = a[varName] ? a[varName].concat(b.mkd_content) : [b.mkd_content];
    } else if (a[varName]) {
      // complain loudly if there are conflicting variable names
      // but allow them for arrays
      throw new Error(`duplicate key name ${varName}`);
    } else {
      a[varName] = b.mkd_content;
    }

    return a;
  }, {} as any);
}
