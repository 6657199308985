import React from 'react';
import { StreamModal } from '../common/StreamModal';
import { CaseStudy } from '../../common/fragments/CaseStudy';
import { caseStudiesBasePath } from './templates/config';
import { Button, Col, Div, Img, P, Row } from '../common/Elements';
import { Lazy } from '../common/Lazy';
import { TextVariantMap } from '../common/Text/Text.interface';
import { CaseStudiesCardContent } from './CaseStudiesCardContent';
import MediaContainer from '../common/MediaContainer';

interface CaseStudiesListItemProps {
  content: any;
  caseStudy: CaseStudy;
  index: number;
  requestOpenVideoModal: (streamId: string) => void;
}

export const CaseStudiesListItem: React.FC<CaseStudiesListItemProps> = ({
  content,
  caseStudy,
  index,
  requestOpenVideoModal,
}) => {
  return (
    <>
      {/* Highlight blade, disappears when you use the filters. */}
      {index === 5 && content.highlightLogo && (
        <Div style={{ gridColumn: 'span 3' }}>
          <Div
            data-industry={(caseStudy.industryNew52420 || []).join(' | ').toLowerCase()}
            data-region={(caseStudy.regionNew52520 || []).join(' | ').toLowerCase()}
            data-usecase={(caseStudy.primaryUseCases || []).join(' | ').toLowerCase()}
            data-product={(caseStudy.product || []).join(' | ').toLowerCase()}
            border="all"
            borderColor="gray2"
            paddingHorizontal={[3, 3, 3, 4]}
            paddingVertical={3}
          >
            <Row alignItems="stretch">
              <Col marginBottom={[5, 5, 5, 0]} lg={8} sm={6} className="h-302px">
                {content.highlightImage && (
                  <Lazy heightPercentage={100}>
                    <Img
                      heightPercentage={100}
                      width="100%"
                      className="image-cover"
                      src={content.highlightImage}
                    />
                  </Lazy>
                )}
              </Col>
              <Col lg={4} sm={6}>
                <CaseStudiesCardContent
                  learnMoreUrl={content.highlightRelatedProductReadMoreUrl.replace(
                    'https://www.cloudflare.com',
                    ''
                  )}
                  grouped
                  learnMoreText={content.highlightLearnMoreText}
                  logo={content.highlightLogo}
                  description={content.highlightDescription}
                />
              </Col>
            </Row>
          </Div>
        </Div>
      )}

      {/* Video card */}
      {caseStudy.cloudflareStreamVideoId ? (
        <Div style={{ gridColumn: 'span 2' }}>
          <Div
            data-industry={(caseStudy.industryNew52420 || []).join(' | ').toLowerCase()}
            data-region={(caseStudy.regionNew52520 || []).join(' | ').toLowerCase()}
            data-usecase={(caseStudy.primaryUseCases || []).join(' | ').toLowerCase()}
            data-product={(caseStudy.product || []).join(' | ').toLowerCase()}
            border="all"
            borderColor="gray2"
            paddingHorizontal={[3, 3, 3, 4]}
            paddingVertical={3}
          >
            <Row alignItems="stretch">
              <Col marginBottom={[5, 5, 5, 0]} lg={6}>
                {caseStudy.ref_streamVideoThumbnail && caseStudy.ref_streamVideoThumbnail.file && (
                  <Lazy style={{ height: '302px' }}>
                    <Img
                      className="pointer image-cover"
                      heightPercentage={100}
                      width="100%"
                      src={
                        caseStudy.twoCardPreviewImage && caseStudy.twoCardPreviewImage.file
                          ? caseStudy.twoCardPreviewImage.file.publicURL
                          : caseStudy.ref_streamVideoThumbnail.file.publicURL
                      }
                    />
                    <Div
                      className="js-case-study-stream-video stream-video-thumbnail-overlay"
                      data-name={caseStudy.cloudflareStreamVideoId}
                      onClick={() => requestOpenVideoModal(caseStudy.cloudflareStreamVideoId)}
                    ></Div>
                  </Lazy>
                )}
                <Div data-name={caseStudy.cloudflareStreamVideoId}></Div>
              </Col>
              <Col lg={6}>
                <CaseStudiesCardContent
                  learnMoreUrl={`/${caseStudiesBasePath}/${caseStudy.nameUrlSlug}`}
                  logo={caseStudy?.logo?.file?.publicURL || ''}
                  grouped
                  description={caseStudy.shortDescription}
                />
              </Col>
            </Row>
          </Div>
        </Div>
      ) : (
        /* Single card */
        <Div>
          <Div
            className=" { cardDisplayClass } js-case-study-card js-case-study-single-card"
            data-industry={(caseStudy.industryNew52420 || []).join(' | ').toLowerCase()}
            data-region={(caseStudy.regionNew52520 || []).join(' | ').toLowerCase()}
            data-usecase={(caseStudy.primaryUseCases || []).join(' | ').toLowerCase()}
            data-product={(caseStudy.product || []).join(' | ').toLowerCase()}
            border="all"
            borderColor="gray2"
            paddingHorizontal={[3, 3, 3, 4]}
            paddingVertical={3}
            style={{ height: '352px' }}
          >
            <CaseStudiesCardContent
              learnMoreUrl={`/${caseStudiesBasePath}/${caseStudy.nameUrlSlug}`}
              logo={caseStudy?.logo?.file?.publicURL || ''}
              description={caseStudy.shortDescription}
            />
          </Div>
        </Div>
      )}
    </>
  );
};

interface CaseStudiesListProps {
  content: any;
  caseStudies: CaseStudy[];
  isTruncated: boolean;
  loadMore: () => void;
}

export const CaseStudiesList: React.FC<CaseStudiesListProps> = props => {
  const [caseStudyStreamId, setCaseStudyStreamId] = React.useState(null as null | string);

  return (
    <>
      <Row>
        <Div className="js-no-results dn ph3 center">
          <P className="fw7 lh-copy f5 tc">{props.content.noResults}</P>
        </Div>
      </Row>
      <MediaContainer>
        {size => (
          <Div
            style={{
              display: size.lg ? 'grid' : 'flex',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gridAutoFlow: 'dense',
              gridGap: '32px',
            }}
            flexWrap="wrap"
            marginBottom={[2, 2, 2, 4]}
          >
            {caseStudyStreamId && (
              <StreamModal
                streamId={caseStudyStreamId}
                isOpen={!!caseStudyStreamId}
                closeModal={() => setCaseStudyStreamId(null)}
              />
            )}

            {props.caseStudies.map((caseStudy, i) => (
              <CaseStudiesListItem
                content={props.content}
                caseStudy={caseStudy}
                index={i}
                key={caseStudy.id}
                requestOpenVideoModal={streamId => setCaseStudyStreamId(streamId)}
              />
            ))}
          </Div>
        )}
      </MediaContainer>

      <Div display="flex" justifyContent="center">
        {props.isTruncated && (
          <Button
            type="button"
            aria-label={props.content.loadMore}
            backgroundColor="transparent"
            borderColor="blue1"
            border="all"
            color="blue1"
            className="wide-btn pointer"
            hovered={{
              borderColor: 'bluehover',
              color: 'bluehover',
            }}
            focused={{
              outlineWidth: 2,
              outlineStyle: 'solid',
              outlineColor: 'blue1',
              outlineInnerOffset: 2,
              borderColor: 'transparent',
            }}
            borderWidth={2}
            paddingVertical={2}
            {...TextVariantMap.learnmore}
            onClick={props.loadMore}
          >
            {props.content.loadMore}
          </Button>
        )}
      </Div>
    </>
  );
};
